<template>
  <a-card>
    <a-page-header
        title="标签筛选结果"
    />

    <a-row class="flex">
      <a-col flex="auto" class="flex">
        <div class="col">
          <span>主体(品牌)：</span>
          <a-select
              v-model="form.principalId"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 260px;"
              @change="handlePrincipalChange"
              :allow-clear="true"
          >
            <a-select-option
                v-for="item in principalList"
                :key="item.value"
                :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col">
          <span>大区：</span>
          <a-select
              v-model="form.regionId"
              :disabled="!form.principalId  || form.principalId === ''"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 220px;"
              @change="getLabelDiagnoseData"
              :allow-clear="true"
          >
            <a-select-option
                v-for="item in regionList"
                :key="item.value"
                :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
      <a-col class="tools">
        <a-space>
          <a-button type="primary" @click="exportFile" :loading="exportLoading">导出</a-button>
          <a-button type="primary" @click="handleJoinGroup" :disabled="!selectedRecordIds.length">
            加入分组{{ selectedRecordIds.length > 0 ? `(${ selectedRecordIds.length })` : "" }}
          </a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :row-key="(record, index) => record.id"
        :pagination="false"
        :row-selection="{
        selectedRowKeys: selectedRecordIds,
        onSelect: onSelect,
        onSelectAll: onSelectAll
      }"
    >
      <div slot="area" slot-scope="text, record">
        <div class="row-box">
          <div class="title title-1"><span>大</span><span>区：</span></div>
          <div>{{ record.regionNames || "-" }}</div>
        </div>
        <div class="row-box">
          <div class="title title-1"><span>城</span><span>市：</span></div>
          <div>{{ record.cityName || "-" }}</div>
        </div>
      </div>
      <div slot="tags" slot-scope="text, record">
        <div class="row-box"
             v-for="(item,index) of record.labelsList"
             :key="index"
             v-if="record.labelsList && record.labelsList.length > 0">
          <div class="title title-2">
            <span style="color:#40a9ff;">{{ item.nameThree || "-" }}：</span>
          </div>
          <a-tooltip>
            <template #title>{{ item.nameFour }}</template>
            <div class="text1">{{ item.nameFour || "-" }}</div>
          </a-tooltip>
        </div>
        <div v-else>暂无标签</div>
      </div>
      <div slot="action" slot-scope="text, record">
        <base-button
            :type="'link'"
            :title="'查看详情'"
            @onClickBtn="jumpDetailPage(record.id)"
        ></base-button>
        <base-button
            :type="'link'"
            :title="'加入分组'"
            @onClickBtn="handleOperate(record)"
        ></base-button>
      </div>
    </a-table>

    <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />

    <!--添加到分组-->
    <JoinDealerGroupModal
        v-model="joinDealerGroupVisible"
        :default-form="joinDealerGroupForm"
        :group-list="joinDealerGroupList"
        :confirm-loading="joinDealerGroupLoading"
        @add-group="addDealerGroupVisible = true"
        @confirm="handleJoinDealerGroupConfirm"
    />

    <!--新建分组-->
    <AddDealerGroupModal
        ref="addDealerGroupModal"
        v-model="addDealerGroupVisible"
        :form="addDealerGroupForm"
        :ok-loading="addDealerGroupLoading"
        @staff-select="handleAddDealerGroupStaffSelect"
        @ok="handleAddDealerGroupOk"
    />

    <!--添加员工-->
    <BaseOrgModal
        v-model="baseOrgVisible"
        :checked-data="baseOrgCheckedData"
        :multiple="baseOrgMultiple"
        @confirm="handleBaseOrgConfirm"
    />

  </a-card>
</template>

<script>
import baseApi from "@/api/base";
import dealerApi from "@/api/dealer";
import JoinDealerGroupModal from "@/components/JoinDealerGroupModal";
import Api from "@/api/tagManage";
import AddDealerGroupModal from "@/components/AddDealerGroupModal";
import BaseOrgModal from "@/components/BaseOrgModal";

export default {
  data() {
    return {
      form: {
        principalId: "",
        regionId: ""
      },
      loading: false,
      columns: [
        {
          align: "center",
          title: "经销商名称",
          dataIndex: "name",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "编码",
          dataIndex: "storeCode",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "公司名称",
          dataIndex: "companyName",
          width: "15%",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "主体",
          dataIndex: "principalNames",
          width: "15%",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "地区",
          scopedSlots: {customRender: "area"}
        },
        {
          align: "center",
          title: "诊断标签",
          width: "20%",
          scopedSlots: {customRender: "tags"}
        },
        {
          align: "center",
          title: "操作",
          key: "action",
          width: 100,
          fixed: "right",
          scopedSlots: {customRender: "action"}
        }
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      principalList: [{label: "全部", value: ""}],
      regionList: [{label: "全部", value: ""}],
      selectedRecordIds: [],
      joinDealerGroupVisible: false,
      joinDealerGroupType: "",
      joinDealerGroupForm: {
        dealerIds: [],
        dealerGroupIds: [],
        dealerGroups: []
      },
      joinDealerGroupList: [],
      joinDealerGroupLoading: false,
      exportLoading: false,
      addDealerGroupVisible: false,
      tagsData: {},
      addDealerGroupForm: {
        groupType: 1,
        groupName: "",
        leaders: [],
        coordinators: []
      },
      addDealerGroupLoading: false,
      baseOrgVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: true,
    };
  },
  components: {JoinDealerGroupModal, AddDealerGroupModal, BaseOrgModal},
  mounted() {
    const {tagsData} = this.$route.query;
    if(tagsData) {
      this.tagsData = JSON.parse(tagsData);
      this.getLabelDiagnoseData();
    }
    this.fetchPrincipal();
  },
  methods: {
    onSelect(record, selected, selectedRows, nativeEvent) {
      // console.log(record, selected, selectedRows, nativeEvent)

      const index = this.selectedRecordIds.findIndex(id => id === record.id);

      if(selected && index === -1) { // 选择时
        this.selectedRecordIds.push(record.id);
      }

      if(!selected && index > -1) { // 取消选择时
        this.selectedRecordIds.splice(index, 1);
      }
    },
    onSelectAll(selected, selectedRows, changeRows) {
      // console.log(selected, selectedRows, changeRows)

      if(selected) {
        selectedRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.id);

          index === -1 && this.selectedRecordIds.push(row.id);
        });
      } else {
        changeRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.id);

          index > -1 && this.selectedRecordIds.splice(index, 1);
        });
      }
    },
    // 获取主体列表
    async fetchPrincipal() {
      try {
        const {code, data, message} = await baseApi.fetchPrincipalList();

        if(code === 200) {
          this.principalList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch(e) {
        console.error(e);
        this.principalList = [];
      }
    },
    // 获取大区列表
    async fetchRegion(principalId) {
      if(!principalId) {
        this.regionList = [{label: "全部", value: ""}];
        return false;
      }

      try {
        const params = {principalId};
        const {code, data, message} = await baseApi.fetchRegionList(params);

        if(code === 200) {
          this.regionList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.region_names, value: item.region_id};
            })
          ];
        } else {
          this.$message.error(message);
          this.regionList = [];
        }
      } catch(e) {
        console.error(e);
        this.regionList = [];
      }
    },
    handlePrincipalChange(val) {
      this.form.regionId = "";
      this.fetchRegion(val);
      this.getLabelDiagnoseData();
    },
    async exportFile() {
      this.exportLoading = true;
      try {
        let params = {
          principalId: this.form.principalId,
          regionId: this.form.regionId,
          effectStartTime: this.tagsData.effectStartTime,
          effectEndTime: this.tagsData.effectEndTime,
          labelIds: this.tagsData.labelIds,
          size: this.pagination.pageSize,
          page: this.pagination.current
        };
        await Api.exportLabelDiagnoseData(params);
        this.exportLoading = false;
      } catch(e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getLabelDiagnoseData();
    },
    getLabelDiagnoseData() {
      this.loading = true;
      let params = {
        principalId: this.form.principalId,
        regionId: this.form.regionId,
        effectStartTime: this.tagsData.effectStartTime,
        effectEndTime: this.tagsData.effectEndTime,
        labelIds: this.tagsData.labelIds,
        size: this.pagination.pageSize,
        page: this.pagination.current
      };
      Api.getLabelDiagnoseData(params).then((res) => {
        this.loading = false;
        this.selectedRecordIds = [];
        if(res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.$message.error("获取数据失败");
          this.dataList = [];
          this.pagination.total = 0;
        }
      });
    },
    jumpDetailPage(id) {
      let routeUrl = this.$router.resolve({
        name: "dealerDetail",
        query: {
          dealer_id: id
        }
      });

      window.open(routeUrl.href, "_blank");
    },
    handleJoinGroup() {
      this.openJoinDealerGroupModal("multiple", [...this.selectedRecordIds]);
    },
    openJoinDealerGroupModal(type, dealerIds) {
      this.joinDealerGroupVisible = true;
      this.joinDealerGroupType = type;
      this.joinDealerGroupForm.dealerIds = dealerIds;
      this.fetchMyDealerGroupList();
    },
    // 获取我创建的经销商分组列表
    async fetchMyDealerGroupList() {
      try {
        const {code, data, message} = await dealerApi.fetchMyDealerGroupList();

        if(code === 200) {
          this.joinDealerGroupList = (data || []).filter(item => item.userType === 1);
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
      }
    },
    // 处理加入经销商分组确定
    async handleJoinDealerGroupConfirm(form) {
      this.joinDealerGroupLoading = true;
      try {
        const {dealerIds, dealerGroups} = form;
        const params = {dealerIds, dealerGroups};
        const {code, msg, errmsg} = await dealerApi.joinDealerGroup(params);

        this.joinDealerGroupLoading = false;

        if(code === 0) {
          this.joinDealerGroupType === "single" && this.$message.success("已将该经销商加入分组！");

          if(this.joinDealerGroupType === "multiple") {
            this.$message.success(`已将${ dealerIds.length }个经销商加入分组！`);
            this.getLabelDiagnoseData();
          }

          this.joinDealerGroupVisible = false;
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
        this.joinDealerGroupLoading = false;
      }
    },
    handleAddDealerGroupStaffSelect(type) {
      this.staffSelectType = type;
      this.baseOrgVisible = true;

      if(this.staffSelectType === "leaders") {
        const {leaders} = this.addDealerGroupForm;
        this.baseOrgCheckedData = leaders.length > 0 ? [leaders[0].id] : [];
        this.baseOrgMultiple = false;
      }

      if(this.staffSelectType === "coordinators") {
        const {coordinators} = this.addDealerGroupForm;
        this.baseOrgCheckedData = coordinators.length > 0 ? coordinators.map(item => item.id) : [];
        this.baseOrgMultiple = true;
      }
    },

    // 处理新建经销商分组确定
    async handleAddDealerGroupOk() {
      this.addDealerGroupLoading = true;
      try {
        const {groupType, groupName, leaders, coordinators} = this.addDealerGroupForm;
        const params = {
          groupType,
          groupName
        };

        if(groupType === 1) {
          params.leaderId = leaders[0].id;
          params.coordinatorIds = coordinators.map(item => item.id);
        }

        const {code, msg, errmsg} = await dealerApi.addDealerGroup(params);

        if(code === 0) {
          this.$message.success("新建经销商分组成功");
          this.addDealerGroupLoading = false;
          this.addDealerGroupVisible = false;
          this.$refs.addDealerGroupModal.resetForm();
          this.fetchMyDealerGroupList();
        } else {
          this.$message.error(msg || errmsg);
          this.addDealerGroupLoading = false;
        }
      } catch(e) {
        console.error(e);
        this.addDealerGroupLoading = false;
      }
    },
    handleBaseOrgConfirm(val) {
      if(this.staffSelectType === "leaders") {
        this.addDealerGroupForm.leaders = val.checkedUser.map(item => {
          return {id: item.key, name: item.title};
        });
      }

      if(this.staffSelectType === "coordinators") {
        this.addDealerGroupForm.coordinators = val.checkedUser.map(item => {
          return {id: item.key, name: item.title};
        });
      }
    },
    handleOperate(record) {
      this.openJoinDealerGroupModal("single", [record.id]);
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex {
  display: flex;
  margin-bottom: 20px;
}

.col {
  margin-right: 10px;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  .title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    &-1 {
      width: 50px;
    }

    &-2 {
      min-width: 44px;
      max-width: 150px;
      text-align: left;
      white-space:nowrap
    }
  }

  .text1 {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}
</style>
